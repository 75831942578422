<template>
  <div>
    <v-card>
      <v-card-title class="mt-4 mb-2"> Alarm Kuralları </v-card-title>
      <v-card-text>
        <table dense>
          <thead>
            <tr>
              <th v-for="item in headers" :key="item.value" :width="item.width">
                {{ item.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in rules" :key="i">
              <td>
                <div class="mt-0 mb-0 pt-0 pb-0 ml-4 mr-4">
                  {{ item["device"] }}
                </div>
              </td>
              <td>
                <v-select
                  class="mt-1 mb-1 ml-4 mr-4 pt-1 pb-1"
                  v-model="item['timeperiod']"
                  label=" "
                  dense
                  attach=""
                  outlined
                  hide-details
                  :items="timeperiod"
                />
              </td>

              <td>
                <v-switch
                  class="mt-2 mb-0 pt-0 pb-0 ml-4 mr-4"
                  v-model="item.status"
                  inset
                />
              </td>
            </tr>
          </tbody>
        </table>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary darken-1" text @click="save(item)">
          Kuralı Kaydet
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { getDataloggerList } from "@/api/Request/datalogger";
import helper from "@/api/helper";
import { getAlarmList, setAlarm, getAlarmRuleList } from "@/api/Request/alarm";
export default {
  props: ["item"],
  components: {},
  data: () => ({
    headers: [],
    dessert: [],
    prefix: "",
    companyCode: "",
    timeperiod: [],
    rules: [],
  }),
  created() {
    this.headers = [
      { text: "Veri kaydedici", value: "device", width: "200px" },
      { text: "Geçikme Periyodu", value: "timeperiod", width: "300px" },
      { text: "Durum", value: "status", width: "200px" },
    ];

    for (let m = 10; m < 60; m += 10) {
      this.timeperiod.push({
        text: m + " dakika",
        value: m + "minute",
      });
    }

    for (let h = 1; h < 25; h += 1) {
      this.timeperiod.push({
        text: h + " saat",
        value: h + "hour",
      });
    }
    console.log(this.timeperiod);
    this.selectedFarmFunc();
  },
  mounted() {
    this.dessert = this.item;

    if ("rules" in this.item) {
      this.rules = this.item.rules;
    }
    console.log(this.item);
    console.log(this.dessert);
    console.log(this.rules);
  },

  methods: {
   async selectedFarmFunc() {
      const {selectedFarm} = await this.$store.getters;
      console.log(selectedFarm)

      this.companyCode = selectedFarm.companyCode;
      this.prefix = selectedFarm.prefix;

      this.getAlarm();
    },
    getAlarm() {
      const params = {
        station: {
          prefix: this.prefix,
          companyCode: this.companyCode,
        },
        condiniton: {
          alarmCode: "DataLoggerAccess",
        },
      };
      getAlarmList(params).then((res) => {
        console.log(res);

        if (helper.resIsEmpty(res)) {
          const alarm = res.data.data[0];
          let r = false;
          if ("rules" in alarm) {
            if (alarm.rules.length > 0) {
              this.rules = alarm.rules;
              r = true;
            }
          }
          if (r == false) {
            this.getDatalogger();
          }
        } else {
          this.getDatalogger();
        }

        // this.getDatalogger()
      });
    },

    save(item) {
      item.rules = this.rules;
      const params = {
        station: {
          prefix: this.prefix,
          companyCode: this.companyCode,
        },
        condiniton: {
          alarmCode: "DataLoggerAccess",
        },
        data: item,
      };
      console.log(params);

      setAlarm(params).then((r) => {
        console.log(r);
      });
    },
    getDatalogger() {
      const params = {
        condiniton: {
          prefix: this.prefix,
        },
      };

      const dessert = [];
      this.dessert = [];
      getDataloggerList(params).then((res) => {
        console.log(res);

        if (helper.resIsEmpty(res)) {
          Object.keys(res.data.data).forEach((r) => {
            console.log(res.data.data[r]);
            dessert.push({
              device: res.data.data[r].deviceName,
              timeperiod: "1hour",
              status: false,
            });
          });
          console.log(dessert);

          this.rules = dessert;
        }
      });
    },
  },
};
</script>


<style  scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

td {
  border: 1px solid #dddddd;
  text-align: left;
}
</style>