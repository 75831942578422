<template>
  <div>
    <v-card>
      <v-card-title class="mt-4 mb-2"> Alarm Kuralları </v-card-title>
      <v-card-text>
        <table dense>
          <thead>
            <tr>
              <th v-for="item in headers" :key="item.value" :width="item.width">
                {{ item.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in rules" :key="i">
              <td>
                <div class="mt-0 mb-0 pt-0 pb-0 ml-4 mr-4">
                  {{ item["alarmName"] }}
                </div>
              </td>
              <td>
                <v-switch
                  class="mt-2 mb-0 pt-0 pb-0 ml-4 mr-4"
                  v-model="item.status"
                  inset
                />
              </td>
              <td>
                <div class="text-center">
                  <v-icon small class="mr-2" @click="editItem(item, i)">
                    mdi-pencil
                  </v-icon>

                  <v-icon small @click="deleteItem(item, i)">
                    mdi-delete
                  </v-icon>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary darken-1" text @click="newRule()">
          Kuralı Ekle
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="clientAddAlarmModel" width="500px">
      <v-card>
        <v-card-title> Kural Ekle</v-card-title>
        <v-card-text>
          <v-col cols="12" class="">
            <v-text-field
              v-model="ruledata.alarmName"
              label="İsim*"
              single-line
              outlined
              dense
              hide-details
            />

            <!--  <v-select
              v-model="alarmTypeModel"
              :items="alarmType"
              label="Kural Tipi"
              class="mb-0 mt-3 pt-1 pb-0"
              outlined
              dense
              required
              hide-details
            >
            </v-select> -->

            <v-select
              ref="ruledata.santral"
              v-model="ruledata.santral"
              :items="santralList"
              label="santral"
              class="mb-0 mt-3 pt-1 pb-0"
              outlined
              dense
              required
              hide-details
            />
            <v-select
              v-model="ruledata.channel"
              :items="alarmDeviceTypeList"
              label="Cihaz Tipi"
              class="mb-0 mt-3 pt-1 pb-0"
              outlined
              dense
              required
              hide-details
            >
            </v-select>

            <v-select
              v-if="ruledata.channel == 'INVERTER'"
              v-model="ruledata.device"
              :items="inverterList[ruledata.santral]"
              label="Inverter"
              class="mb-0 mt-3 pt-1 pb-0"
              outlined
              multiple
              dense
              required
              hide-details
            />

            <v-select
              v-if="ruledata.channel == 'INVERTER'"
              v-model="ruledata.measure"
              :items="inverterMeasureList"
              label="Inverter"
              class="mb-0 mt-3 pt-1 pb-0"
              outlined
              dense
              multiple
              required
              hide-details
            />

            <v-select
              v-if="ruledata.channel == 'STRINGBOX'"
              v-model="ruledata.device"
              :items="stringboxList[ruledata.santral]"
              label="Stringbox"
              class="mb-0 mt-3 pt-1 pb-0"
              outlined
              multiple
              dense
              required
              hide-details
            />

            <v-select
              v-if="ruledata.channel == 'STRINGBOX'"
              v-model="ruledata.measure"
              :items="stringboxMeasureList"
              label="Stringbox"
              class="mb-0 mt-3 pt-1 pb-0"
              outlined
              dense
              multiple
              required
              hide-details
            />

            <v-select
              v-if="ruledata.channel == 'SENSOR'"
              v-model="ruledata.device"
              :items="sensorList[ruledata.santral]"
              label="Sensor"
              class="mb-0 mt-3 pt-1 pb-0"
              outlined
              multiple
              dense
              required
              hide-details
            />

            <v-select
              v-if="ruledata.channel == 'SENSOR'"
              v-model="ruledata.measure"
              :items="sensorMeasureList"
              label="Sensor"
              class="mb-0 mt-3 pt-1 pb-0"
              multiple
              outlined
              dense
              required
              hide-details
            />

            <v-select
              v-model="ruledata.severity_id"
              :items="severity"
              label="Seviye* "
              class="mb-0 mt-3 pt-1 pb-0"
              outlined
              dense
              required
              hide-details
            >
            </v-select>

            <v-select
              v-model="ruledata.condition"
              :items="condition"
              label="Koşul* "
              class="mb-0 mt-3 pt-1 pb-0"
              outlined
              dense
              required
              hide-details
            />

            <v-col class="d-flex mb-0 mt-1 pt-1 pb-0 ml-0 pl-0 mr-0 pr-0">
              <v-text-field
                v-if="['inrange', 'notrange'].indexOf(ruledata.condition) == -1"
                v-model="ruledata.value"
                class="mb-0 mt-0 pt-1 pb-0 ml-1"
                label="Deger*"
                number
                single-line
                outlined
                dense
                hide-details
              />
              <v-text-field
                v-model="ruledata.min"
                label="min*"
                v-if="['inrange', 'notrange'].indexOf(ruledata.condition) > -1"
                class="mb-0 mt-0 pt-1 pb-0 mr-1"
                number
                single-line
                outlined
                dense
                hide-details
              />

              <v-text-field
                v-if="['inrange', 'notrange'].indexOf(ruledata.condition) > -1"
                v-model="ruledata.max"
                class="mb-0 mt-0 pt-1 pb-0 ml-1"
                label="maks*"
                number
                single-line
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col class="d-flex mb-0 mt-0 pt-1 pb-0 ml-0 pl-0 mr-0 pr-0">
              <v-card class="pl-2 pr-2 col-12">
                <span class="text-h7 mb-0 pb-0">
                  <b>
                    <v-switch
                      v-model="ruledata.delaystatus"
                      label="Geçikme"
                      inset
                    />
                    ({{ ruledata.delay }} dakika)
                  </b>
                  Sistem, alarmı açmadan önce gecikmeyi bekler.
                </span>

                <v-slider
                  v-model="ruledata.delay"
                  class="align-center"
                  max="200"
                  min="10"
                  hide-details
                />
              </v-card>
            </v-col>

            <v-col class="d-flex mb-0 mt-0 pt-1 pb-0 ml-0 pl-0 mr-0 pr-0">
              <v-card class="pl-2 pr-2 col-12">
                <span class="text-h7">
                  <b>
                    <v-switch
                      v-model="ruledata.ignore_night_status"
                      label="Karanlığı Yoksay"
                      inset
                    />
                    Karanlığı Yoksay <br />({{ ruledata.ignore_night }}
                    dakika)
                  </b>
                </span>
                Gün doğumundan sonra ve gün batımından önce seçilen dakika kadar
                uyarı kontrolünü atla
                <v-slider
                  v-model="ruledata.ignore_night"
                  class="align-center"
                  max="200"
                  min="10"
                  hide-details
                />
              </v-card>
            </v-col>

            <v-col class="d-flex mb-0 mt-0 pt-1 pb-0">
              <v-switch v-model="ruledata.status" label="Durum" inset />
            </v-col>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary darken-1" text @click="save">
            Ayarı Kaydet
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { getAlarmList, setAlarm, getAlarmRuleList } from "@/api/Request/alarm";
export default {
  props: ["item"],
  components: {},
  data: () => ({
    headers: [],
    dessert: [],
    prefix: "",
    companyCode: "",
    timeperiod: [],
    rules: [],
    clientAddAlarmModel: false,
    alarmType: [],
    alarmTypeModel: "",
    severity: [],
 
    seletedSantral: "mars-2",

    alarmDeviceTypeModel: "",
    alarmDeviceTypeList: [],
    alarmName: "",
    conditionModel: "",

    //inverterList: [],
    inverterModel: [],
    // stringboxList: [],
    stringboxModel: [],
    //sensorList: [],
    sensorModel: [],

    inverterMeasureList: [],
    stringboxMeasureList: [],
    sensorMeasureList: [],

    inverterMeasureModel: [],
    stringboxMeasureModel: [],
    sensorMeasureModel: [],
    status: false,
    alarmValue: null,
    maksValue: null,
    minValue: null,
    selectedRule: {},
    selectedIndex: null,

    ruledata: {},
  }),
  created() {
    this.newRuleData();
    this.alarmType = [
      { text: "Cihaz", value: "device" },
      { text: "Terim", value: "term" },
    ];

    this.alarmDeviceTypeList = [
      { text: "İnverter", value: "INVERTER" },
      { text: "String", value: "STRINGBOX" },
      { text: "Sensör", value: "SENSOR" },
    ];

    this.severity = [
      { text: "Düşük", value: "1" },
      { text: "Orta", value: "2" },
      { text: "Yüksek", value: "3" },
      { text: "Kritik", value: "4" },
    ];

    this.condition = [
      { text: "Eşit", value: "eq" },
      { text: "Küçüktür ", value: "max" },
      { text: "Büyüktür", value: "min" },
      { text: "Aralık içinde", value: "inrange" },
      { text: "Aralık dışında", value: "notrange" },
    ];

    this.headers = [
      { text: "İsim", value: "alarmName", width: "200px" },
      { text: "Durum", value: "status", width: "300px" },
      { text: "Seçenekler", value: "options", width: "200px" },
    ];

    this.selectedFarmFunc();
  },
  mounted() {
    this.dessert = this.item;

    if ("rules" in this.item) {
      this.rules = this.item.rules;
    }
  },
  computed: {
    santralList() {
      const { santralRawData } = this.$store.getters;
      const santral = [];
      if (Object.keys(santralRawData).length > 0) {
        console.log(santralRawData);
        Object.keys(santralRawData).forEach((i) => {
          santral.push({
            text: santralRawData[i].santral,
            value: santralRawData[i].santral,
          });
        });
        return santral;
      }
    },

    inverterList() {
      const { inverterMeasureList, santralInverterData } = this.$store.getters;
      console.log(santralInverterData);
      const invList = {};
      Object.keys(santralInverterData).forEach((i) => {
        const temp = [];
        const inverter = santralInverterData[i];
        console.log(inverter);
        Object.keys(inverter).forEach((k) => {
          console.log(inverter[k]);
          temp.push({
            text: inverter[k].INVERTERNAME,
            value: inverter[k].INVERTER,
          });
        });
        invList[i] = temp;
      });
      this.inverterMeasureList = inverterMeasureList;
      console.log(invList);
      return invList;
    },
    stringboxList() {
      const { santralStringboxData, stringboxMeasureList } =
        this.$store.getters;
      const stringList = {};
      Object.keys(santralStringboxData).forEach((i) => {
        const strinbox = santralStringboxData[i];
        const temp = [];
        Object.keys(strinbox).forEach((k) => {
          console.log(strinbox[k]);
          temp.push({
            text: strinbox[k].STRINGBOXNAME,
            value: strinbox[k].STRINGBOX,
          });
        });
        stringList[i] = temp;
      });
      this.stringboxMeasureList = stringboxMeasureList;
      return stringList;
    },
    sensorList() {
      const { santralSensorData, sensorMeasureList } = this.$store.getters;
      const sensorList = {};
      console.log(sensorMeasureList);
      Object.keys(santralSensorData).forEach((i) => {
        const sensor = santralSensorData[i];
        const temp = [];
        Object.keys(sensor).forEach((k) => {
          temp.push({
            text: sensor[k].SENSORNAME,
            value: sensor[k].SENSOR,
          });
        });
        sensorList[i] = temp;
      });
      this.sensorMeasureList = [
        {
          formuletype: "avg",
          text: "Irradiation",
          key: "Irradiation",
          unit: "mv",
          type: "float",
        },
        {
          formuletype: "avg",
          text: "TempBoard",
          key: "TempBoard",
          unit: "°C",
          type: "float",
        },
        {
          formuletype: "avg",
          text: "PanelTemp",
          key: "PanelTemp",
          unit: "°C",
          type: "float",
        },
      ];
      return sensorList;
    },
  },

  methods: {
    newRuleData() {
      this.ruledata = {
        alarmName: "",
        santral: "",
        channel: "",
        severity_id: "",
        condition: "",
        status: false,
        ignore_night: "",
        delay: "",
        device: [],
        measure: "",
        max: "",
        min: "",
        condition: "",
        value: "",
        ignore_night_status: false,
        delaystatus: false,
      };
    },
    async selectedFarmFunc() {
      const { selectedFarm } = await this.$store.getters;
      this.companyCode = selectedFarm.companyCode;
      this.prefix = selectedFarm.prefix;
    },

    newRule() {
      this.clientAddAlarmModel = true;
      this.selectedIndex = null;
      this.newRuleData();
    },

    save() {
      if (!Object.hasOwn(this.item, "rules")) {
        this.item.rules = [];
      }

      if (!Array.isArray(this.item.rules)) {
        this.item.rules = [];
      }

      if (this.selectedIndex == null) {
        this.item.rules.push(this.ruledata);
      } else {
        this.item.rules[this.selectedIndex] = this.ruledata;
      }
      this.clientAddAlarmModel = false;
      const params = {
        station: {
          prefix: this.prefix,
          companyCode: this.companyCode,
        },
        condiniton: {
          alarmCode: "UserDefinedAlarms",
        },
        data: this.item,
      };

    

      setAlarm(params).then((r) => {
        console.log(r);
      });
    },
    deleteItem(rule, index) {
      var removed = this.item.rules.splice(index, 1);

      const params = {
        station: {
          prefix: this.prefix,
          companyCode: this.companyCode,
        },
        condiniton: {
          alarmCode: "UserDefinedAlarms",
        },
        data: this.item,
      };

      setAlarm(params).then((r) => {
        console.log(r);
      });
    },
    editItem(rule, index) {
      this.ruledata = rule;
      this.clientAddAlarmModel = true;
      this.selectedIndex = index;
    },
  },
};
</script>


<style  scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

td {
  border: 1px solid #dddddd;
  text-align: left;
}
</style>