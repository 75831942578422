var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","max-width":"800px","height":"700px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticClass:"text-h5 pt-2 pb-1",staticStyle:{"height":"35px","background-color":"white"}},[_c('span',{staticClass:"ml-4 mt-3"},[_vm._v(" "+_vm._s(_vm.item.text)+" ")])]),(Object.keys(_vm.item).length > 0)?_c('div',{staticStyle:{"background-color":"#eff1f5"}},[_c('v-container',[_c('v-card',{},[_c('v-card-text',[_c('table',{attrs:{"width":"100%"}},[_c('tbody',[_c('tr',[_c('td',{attrs:{"width":"20%"}},[_c('span',{staticClass:"text-h7"},[_c('b',[_vm._v("Alarm Açıklaması")])])]),_c('td',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('alarm.' + _vm.item.alarmCode))}})])]),_c('tr',[_c('td',{attrs:{"width":"20%"}},[_c('span',{staticClass:"text-h7"},[_c('b',[_vm._v(" Alarm Durumu")])])]),_c('td',[_c('div',{staticClass:"d-flex"},[_c('v-switch',{attrs:{"inset":""},model:{value:(_vm.item.status),callback:function ($$v) {_vm.$set(_vm.item, "status", $$v)},expression:"item.status"}}),(_vm.item.status)?_c('span',{staticClass:"mt-5",domProps:{"innerHTML":_vm._s(_vm.$t('global.Active'))}}):_c('span',{staticClass:"mt-5",domProps:{"innerHTML":_vm._s(_vm.$t('global.Passive'))}})],1)])]),_c('tr',[_c('td',{attrs:{"width":"20%"}},[_c('span',{staticClass:"text-h7"},[_c('b',[_vm._v(" Alarm İlgilileri (mail)")])])]),_c('td',[_c('v-combobox',{ref:"item.mail",attrs:{"items":_vm.userlist.mail,"chips":"","clearable":"","label":"Alarm göndermek için ilgili kişileri seçiniz","multiple":"","outlined":"","hidden-footer":"","hidden-details":"","hidden-hint":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"small":"","input-value":selected,"close":"","dense":""},on:{"click":select,"click:close":function($event){return _vm.removemail(item.value)}}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(item.text))])])]}}],null,false,1017127913),model:{value:(_vm.item.userlist),callback:function ($$v) {_vm.$set(_vm.item, "userlist", $$v)},expression:"item.userlist"}})],1)]),_c('tr',[_c('td',{attrs:{"width":"20%"}},[_c('span',{staticClass:"text-h7"},[_c('b',[_vm._v(" Alarm İlgilileri(Telegram)")])])]),_c('td',[_c('v-combobox',{ref:"item.telegram",attrs:{"items":_vm.userlist.telegram,"chips":"","clearable":"","label":_vm.$t('Telegram'),"multiple":"","outlined":"","hidden-footer":"","hidden-details":"","hidden-hint":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"small":"","input-value":selected,"close":"","dense":""},on:{"click":select,"click:close":function($event){return _vm.remove(item)}}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(item))])])]}}],null,false,3842737430),model:{value:(_vm.item.telegram),callback:function ($$v) {_vm.$set(_vm.item, "telegram", $$v)},expression:"item.telegram"}})],1)]),(_vm.item.params)?_c('tr',[_c('td',{attrs:{"width":"20%"}},[_c('span',{staticClass:"text-h7"},[_c('b',[_vm._v(" Karanlığı Yoksay "),_c('br'),_vm._v("("+_vm._s(_vm.item.params.ignore_night)+" dakika) ")])])]),_c('td',[_vm._v(" Gün doğumundan sonra ve gün batımından önce seçilen dakika kadar uyarı kontrolünü atla "),_c('v-slider',{staticClass:"align-center",attrs:{"max":"200","min":"10","hide-details":""},model:{value:(_vm.item.params.ignore_night),callback:function ($$v) {_vm.$set(_vm.item.params, "ignore_night", $$v)},expression:"item.params.ignore_night"}})],1)]):_vm._e()])])]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary darken-1","text":""},on:{"click":function($event){return _vm.$emit('setAlarmUpdate', _vm.item)}}},[_vm._v(" Ayarı Kaydet ")])],1)],1),_c('div',{staticClass:"mt-3 mb-2"},[(_vm.item.alarmCode == 'ProductionandCommunication')?_c('ProductionandCommunication',{attrs:{"item":_vm.item}}):_vm._e()],1),_c('div',{staticClass:"mt-3 mb-2"},[(_vm.item.alarmCode == 'DataLoggerAccess')?_c('DataLoggerAccess',{attrs:{"item":_vm.item}}):_vm._e()],1),_c('div',{staticClass:"mt-3 mb-2"},[(_vm.item.alarmCode == 'UserDefinedAlarms')?_c('UserDefinedAlarms',{attrs:{"item":_vm.item}}):_vm._e()],1),_c('div',{staticClass:"mt-3 mb-2"},[(_vm.item.alarmCode == 'InverterErrorWarning')?_c('InverterErrorWarning',{attrs:{"item":_vm.item}}):_vm._e()],1),_c('v-card',[_c('v-btn',{attrs:{"color":"primary darken-1","text":""},on:{"click":function($event){return _vm.$emit('alarmsetting')}}},[_vm._v(" Kapat ")])],1)],1)],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }