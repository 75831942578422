<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="500px" height="500px">
      <v-card>
        <v-card-title>
          <span class="blue--text text-h6"> Alarm Ekle</span>
        </v-card-title>

        <v-divider />
        <v-card-text>
          <div v-for="(item, i) in alarmList" :key="i" class="d-flex mt-1 mb-1">
            <v-checkbox
              dense
              hide-details
              @change="setAlarmUpdate(item, i)"
              class="mt-0 mb-0 pt-0 pb-0"
              :input-value="alarmModel[i]"
              color="primary"
            >
            </v-checkbox>

            <span class="mt-0 mb-0 pt-0 pb-0">
              {{ item.text }}
            </span>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text dense outlined @click="$emit('newalarm')">
            Kapat
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getAlarmList, setAlarm } from "@/api/Request/alarm";

export default {
  props: ["dialog"],
  data: () => ({
    // alarmList: [],
    alarmModel: {},
    selectIndex: null
  }),
  created() {
    this.selectedFarmFunc();
  },
  computed: {
    alarmList() {
      const { alarmlist } = this.$store.getters;

      if (Object.keys(alarmlist).length > 0) {
        Object.keys(alarmlist).forEach(r => {
          this.alarmModel[r] = alarmlist[r].status == "Active" ? true : false;
        });

        return alarmlist;
      }
    }
  },
  watch: {
    dialog() {
      const params = {
        station: {
          companyCode: this.companyCode,
          prefix: this.prefix
        }
      };
      this.$store.dispatch("getAlarmList", params);
    }
  },
  methods: {
    async selectedFarmFunc() {
      const { selectedFarm } = await this.$store.getters;
      this.companyCode = selectedFarm.companyCode;
      this.prefix = selectedFarm.prefix;
    },
    setAlarmUpdate(item, i) {
      this.selectIndex = i;
      const alarmdata = Object.assign({}, item);

      if (item.status == "Active") {
        this.alarmModel[i] = "Passive";
        alarmdata.status = "Passive";
      } else if (item.status == "Passive") {
        this.alarmModel[i] = "Active";
        alarmdata.status = "Active";
      }

      const params = {
        station: {
          companyCode: this.companyCode,
          prefix: this.prefix
        },
        condiniton: {
          alarmCode: alarmdata.alarmCode
        },
        data: alarmdata
      };

      setAlarm(params).then(r => {
        console.log(r);
      });
    }
  }
};
</script>
