<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="800px" height="700px">
      <div
        style="height: 35px; background-color: white"
        class="text-h5 pt-2 pb-1"
      >
        <span class="ml-4 mt-3">
          {{ item.text }}
        </span>
      </div>

      <div
        v-if="Object.keys(item).length > 0"
        style="background-color: #eff1f5"
      >
        <v-container>
          <v-card class="">
            <v-card-text>
              <table width="100%">
                <tbody>
                  <tr>
                    <td width="20%">
                      <span class="text-h7"> <b>Alarm Açıklaması</b></span>
                    </td>
                    <td>
                      <span v-html="$t('alarm.' + item.alarmCode)" />
                    </td>
                  </tr>

                  <tr>
                    <td width="20%">
                      <span class="text-h7"><b> Alarm Durumu</b> </span>
                    </td>
                    <td>
                      <div class="d-flex">
                        <v-switch v-model="item.status" inset />
                        <span
                          v-if="item.status"
                          class="mt-5"
                          v-html="$t('global.Active')"
                        />
                        <span
                          v-else
                          class="mt-5"
                          v-html="$t('global.Passive')"
                        />
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td width="20%">
                      <span class="text-h7">
                        <b> Alarm İlgilileri (mail)</b>
                      </span>
                    </td>
                    <td>
                      <v-combobox
                        ref="item.mail"
                        v-model="item.userlist"
                        :items="userlist.mail"
                        chips
                        clearable
                        label="Alarm göndermek için ilgili kişileri seçiniz"
                        multiple
                        outlined
                        hidden-footer
                        hidden-details
                        hidden-hint
                        dense
                      >
                        <template
                          v-slot:selection="{ attrs, item, select, selected }"
                        >
                          <v-chip
                            small
                            v-bind="attrs"
                            :input-value="selected"
                            close
                            dense
                            @click="select"
                            @click:close="removemail(item.value)"
                          >
                            <strong>{{ item.text }}</strong>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <span class="text-h7">
                        <b> Alarm İlgilileri(Telegram)</b>
                      </span>
                    </td>
                    <td>
                      <v-combobox
                        ref="item.telegram"
                        v-model="item.telegram"
                        :items="userlist.telegram"
                        chips
                        clearable
                        :label="$t('Telegram')"
                        multiple
                        outlined
                        hidden-footer
                        hidden-details
                        hidden-hint
                        dense
                      >
                        <template
                          v-slot:selection="{ attrs, item, select, selected }"
                        >
                          <v-chip
                            small
                            v-bind="attrs"
                            :input-value="selected"
                            close
                            dense
                            @click="select"
                            @click:close="remove(item)"
                          >
                            <strong>{{ item }}</strong>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </td>
                  </tr>

                  <tr v-if="item.params">
                    <td width="20%">
                      <span class="text-h7">
                        <b>
                          Karanlığı Yoksay <br />({{ item.params.ignore_night }}
                          dakika)
                        </b>
                      </span>
                    </td>
                    <td>
                      Gün doğumundan sonra ve gün batımından önce seçilen dakika
                      kadar uyarı kontrolünü atla
                      <v-slider
                        v-model="item.params.ignore_night"
                        class="align-center"
                        max="200"
                        min="10"
                        hide-details
                      />
                    </td>
                  </tr>

                  <!--  <tr v-if="item.params">
                    <td width="20%">
                      <span class="text-h7"> <b> Seç </b></span>
                    </td>
                    <td>
                      <div
                        v-for="(key, text) in item.params.checked_keys"
                        :key="text"
                        class="d-flex mt-1 mb-1"
                      >
                        <v-checkbox
                          @change="
                            setParams(item.params.checked_keys[text], text)
                          "
                          dense
                          hide-details
                          class="mt-0 mb-0 pt-0 pb-0"
                          :input-value="item.params.checked_keys[text]"
                          color="primary"
                        >
                        </v-checkbox>
                        <span class="mt-0 mb-0 pt-0 pb-0">
                          {{ $t("alarm." + text) }}
                        </span>
                      </div>
                    </td>
                  </tr> -->
                </tbody>
              </table>
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="primary darken-1"
                text
                @click="$emit('setAlarmUpdate', item)"
              >
                Ayarı Kaydet
              </v-btn>
            </v-card-actions>
          </v-card>

          <div class="mt-3 mb-2">
            <ProductionandCommunication
              v-if="item.alarmCode == 'ProductionandCommunication'"
              :item="item"
            />
          </div>

          <div class="mt-3 mb-2">
            <DataLoggerAccess
              v-if="item.alarmCode == 'DataLoggerAccess'"
              :item="item"
            />
          </div>

          <div class="mt-3 mb-2">
            <UserDefinedAlarms
              v-if="item.alarmCode == 'UserDefinedAlarms'"
              :item="item"
            />
          </div>

          <div class="mt-3 mb-2">
            <InverterErrorWarning
              v-if="item.alarmCode == 'InverterErrorWarning'"
              :item="item"
            />
          </div>
          <v-card>
            <v-btn color="primary darken-1" text @click="$emit('alarmsetting')">
              Kapat
            </v-btn>
          </v-card>
        </v-container>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import DataLoggerAccess from "./alarmSetting/DataLoggerAccess.vue";
import UserDefinedAlarms from "./alarmSetting/UserDefinedAlarms.vue";
import ProductionandCommunication from "./alarmSetting/ProductionandCommunication.vue";
import InverterErrorWarning from "./alarmSetting/InverterErrorWarning.vue";

export default {
  components: {
    DataLoggerAccess,
    ProductionandCommunication,
    UserDefinedAlarms,
    InverterErrorWarning,
  },
  props: ["dialog", "item", "userlist"],
  data: () => ({
    slider: 90,
    paramsChecked: [],
    mailModel: [],
  }),
  computed: {},
  watch: {},
  created() {},
  methods: {
    setParams(value, item) {
      this.item.params.checked_keys[item] = !value;
      console.log(this.item);
    },

    remove(val) {
      console.log(val);
      console.log(this.item);
      this.item.telegram = this.item.telegram.filter((item) => item !== val);
      console.log(this.item);
    },
    removemail(val) {
      console.log(val);
      console.log(this.item);
      this.item.userlist = this.item.userlist.filter(
        (item) => item.value !== val
      );
      console.log(this.item);
    },
  },
};
</script>
