import request from '@/api/utils';

export function setDataloggerCreate(formdata = {}, method = 'post') {
    const url = '/api/farm/setDataloggerCreate';
    return request.Api(url, formdata, method);
  }


  export function setDataloggerDelete(formdata = {}, method = 'post') {
    const url = '/api/farm/deldatalogger';
    return request.Api(url, formdata, method);
  }

  export function getDataloggerList(formdata = {}, method = 'post') {
    const url = '/api/farm/getDataloggerList';
    return request.Api(url, formdata, method);
  }

  