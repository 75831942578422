<template>
  <v-card>
    <v-card-title class="mt-4 mb-2"> Alarm Kuralları </v-card-title>
    <v-card-text>
      <div
        v-for="(key, text) in item.params.checked_keys"
        :key="text"
        class="d-flex mt-1 mb-1"
      >
        <v-checkbox
          @change="setParams(item.params.checked_keys[text], text)"
          dense
          hide-details
          class="mt-0 mb-0 pt-0 pb-0"
          :input-value="item.params.checked_keys[text]"
          color="primary"
        >
        </v-checkbox>
        <span class="mt-0 mb-0 pt-0 pb-0">
          {{ $t("alarm." + text) }}
        </span>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary darken-1" text @click="save(item)">
        Kuralı Kaydet
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { setAlarm } from "@/api/Request/alarm";

export default {
  props: ["item"],
  data: () => ({
    paramsChecked: [],
  }),
  created() {
    this.selectedFarmFunc();
  },
  computed: {},
  watch: {},
  methods: {
    selectedFarmFunc() {
      const storage = window.localStorage;
      const userCompany = JSON.parse(
        window.atob(storage.getItem("user_company"))
      );
      this.companyCode = userCompany.companyCode;
      this.prefix = userCompany.prefix;
    },
    setParams(value, item) {
      this.item.params.checked_keys[item] = !value;
      console.log(this.item);
    },
    save(item) {
      const params = {
        station: {
          prefix: this.prefix,
          companyCode: this.companyCode,
        },
        condiniton: {
          alarmCode: "ProductionandCommunication",
        },
        data: item,
      };
      console.log(params);

      setAlarm(params).then((r) => {
        console.log(r);
      });
    },
  },
};
</script>